import React, { useEffect, useRef } from "react"
import useCheckpoints from "../../../hooks/use-checkpoints"

type Props = {
  onComplete: () => void
}

const SwipeLine: React.FC<Props> = ({ onComplete }) => {
  const actionAreaRef = useRef<HTMLDivElement>(null)
  const completed = useCheckpoints(actionAreaRef)

  useEffect(() => {
    if (completed) {
      onComplete()
    }
  }, [completed])

  return (
    <div
      ref={actionAreaRef}
      className="absolute inset-0 z-[1337] h-full w-full cursor-grab touch-none select-none"
    >
      <div className="absolute left-[calc(275/375*100%)] top-[calc(320/664*100%)] h-[32px] w-[32px] landscape:left-[calc(1780/2746*100%)] landscape:top-[calc(660/1544*100%)] landscape:h-[56px] landscape:w-[56px]">
        <div className="absolute h-full w-full origin-top-right rotate-[76deg] animate-push-coin-portrait rounded-full bg-green landscape:animate-push-coin-landscape" />

        <div
          data-order={1}
          className="absolute top-0 right-[30%] h-[calc(4*32px)] w-[6vw] -translate-y-1/2 rotate-[-17deg] bg-[fuchsia]/0 landscape:h-[calc(4*56px)] landscape:w-[4vw]"
        ></div>
        <div
          data-order={2}
          className="absolute top-0 right-[calc(30%+10%+8vw)] h-[calc(4*32px)] w-[6vw] -translate-y-1/2 rotate-[-17deg] bg-[fuchsia]/0 landscape:right-[calc(10%+10%+6vw)] landscape:h-[calc(4*56px)] landscape:w-[4vw]"
        ></div>
      </div>
    </div>
  )
}

export default SwipeLine

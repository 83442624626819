import React, { useEffect, useRef } from "react"
import useCheckpoints from "../../../hooks/use-checkpoints"

type Props = {
  onComplete: () => void
}

const SpinKnob: React.FC<Props> = ({ onComplete }) => {
  const actionAreaRef = useRef<HTMLDivElement>(null)
  const completed = useCheckpoints(actionAreaRef)

  useEffect(() => {
    if (completed) {
      onComplete()
    }
  }, [completed])

  return (
    <div
      ref={actionAreaRef}
      className="absolute inset-0 z-[1337] h-full w-full cursor-grab touch-none select-none"
    >
      <div className="absolute left-[calc(95/375*100%)] top-[calc(265/664*100%)] aspect-square w-[calc(155/375*100%)] landscape:left-[calc(900/2746*100%)] landscape:top-[calc(430/1544*100%)] landscape:w-[calc(790/2746*100%)]">
        <div className="absolute top-0 left-0 aspect-square w-full animate-rotate rounded-[50%] bg-green p-[32px] ![mask-composite:subtract] [mask:conic-gradient(#0000_10%,#000),linear-gradient(#000_0_0)_content-box] landscape:p-[56px]"></div>
        <div className="absolute top-0 left-0 aspect-square w-full animate-rotate rounded-[50%] [background:radial-gradient(farthest-side,#BEFF3D_95%,#0000)_50%_0/32px_32px_no-repeat_content-box] landscape:[background:radial-gradient(farthest-side,#BEFF3D_95%,#0000)_50%_0/56px_56px_no-repeat_content-box]"></div>

        <div
          data-order={1}
          className="absolute right-0 top-0 aspect-[1/5] w-[8vw] -translate-y-[4vw] rotate-[80deg] bg-[fuchsia]/0 landscape:aspect-[1/4] landscape:w-[4vw] landscape:translate-y-0 landscape:rotate-[50deg]"
        ></div>
        <div
          data-order={2}
          className="absolute right-0 top-1/2 aspect-[1/5] w-[8vw] -translate-y-[8vw] rotate-[100deg] bg-[fuchsia]/0 landscape:aspect-[1/4] landscape:w-[4vw] landscape:translate-y-0 landscape:rotate-[115deg]"
        ></div>
      </div>
    </div>
  )
}

export default SpinKnob

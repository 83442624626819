import React, { useEffect, useRef } from "react"

type Props = {
  onComplete: () => void
}

const Tap: React.FC<Props> = ({ onComplete }) => {
  const actionAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!actionAreaRef.current) {
      return
    }

    const onTap = () => {
      onComplete()
    }

    actionAreaRef.current.addEventListener("click", onTap)

    return () => {
      actionAreaRef.current?.removeEventListener("click", onTap)
    }
  }, [actionAreaRef])

  return (
    <div
      ref={actionAreaRef}
      className="absolute inset-0 z-[1337] h-full w-full"
    >
      <div className="absolute top-1/2 left-1/2 h-[32px] w-[32px] -translate-x-1/2 -translate-y-1/2">
        <div className="absolute left-[8px] top-[8px] inline-flex h-[32px] w-[32px] animate-pulsate rounded-[50%] border-[3px] border-green opacity-0"></div>
        <div className="absolute top-[16px] left-[16px] h-[16px] w-[16px] rounded-[50%] bg-green"></div>
      </div>
    </div>
  )
}

export default Tap

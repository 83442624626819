import React, { useEffect, useRef } from "react"
import useCheckpoints from "../../../hooks/use-checkpoints"

type Props = {
  onComplete: () => void
}

const DragGachaball: React.FC<Props> = ({ onComplete }) => {
  const actionAreaRef = useRef<HTMLDivElement>(null)
  const completed = useCheckpoints(actionAreaRef)

  useEffect(() => {
    if (completed) {
      onComplete()
    }
  }, [completed])

  return (
    <div
      ref={actionAreaRef}
      className="absolute inset-0 z-[1337] h-full w-full cursor-grab touch-none select-none"
    >
      <div className="absolute left-[calc(188/375*100%)] top-[calc(430/664*100%)] h-[32px] w-[32px] -translate-x-1/2 rotate-180 landscape:left-[calc(1375/2746*100%)] landscape:top-[calc(1200/1544*100%)] landscape:h-[56px] landscape:w-[56px]">
        <div className="h-[32px] w-[32px] animate-drag-gachaball-portrait rounded-full bg-green landscape:h-[56px] landscape:w-[56px] landscape:animate-drag-gachaball-landscape" />
      </div>

      <div
        data-order={2}
        className="absolute left-[calc(188/375*100%)] top-[calc(365/664*100%)] h-[32px] w-full -translate-x-1/2 bg-[fuchsia]/0 landscape:left-[calc(1375/2746*100%)] landscape:top-[calc(1040/1544*100%)] landscape:h-[80px]"
      ></div>
      <div
        data-order={1}
        className="absolute left-[calc(188/375*100%)] top-[calc(405/664*100%)] h-[32px] w-full -translate-x-1/2 bg-[fuchsia]/0 landscape:left-[calc(1375/2746*100%)] landscape:top-[calc(1150/1544*100%)] landscape:h-[80px]"
      ></div>
    </div>
  )
}

export default DragGachaball

import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useWindowSize } from "@react-hook/window-size"
import Countdown from "react-countdown"
import SabucaseyPDPLandscape from "../../videos/shop/sabucasey-pdp-landscape.mp4"
import SabucaseyPDPPortrait from "../../videos/shop/sabucasey-pdp-portrait.mp4"
import DropNumber01 from "../../images/svg/drop-number-01.svg"
import CrossIcon from "../../images/svg/cross.svg"

type Props = {
  visible: boolean
}

const PDP: React.FC<Props> = ({ visible }) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const [showDetails, setShowDetails] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const [width, height] = useWindowSize()
  const [isLandscape, setIsLandscape] = useState(width > height)
  const [bpmPreSaleStarted, setBpmPreSaleStarted] = useState(false)
  const [holdersSaleStarted, setHoldersSaleStarted] = useState(false)

  useEffect(() => {
    setIsLandscape(width > height)
  }, [width, height])

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            shop {
              mintEnabled
              mintUrl
              fallbackUrl
              bpmPreSaleStart
              holdersSaleStart
            }
          }
        }
      }
    `
  )

  const mintUrl = site.siteMetadata.shop.mintEnabled
    ? site.siteMetadata.shop.mintUrl
    : site.siteMetadata.shop.fallbackUrl

  const handleBpmSaleStarted = () => setBpmPreSaleStarted(true)
  const handleHoldersSaleStarted = () => setHoldersSaleStarted(true)

  const renderCountdown = e => {
    const { hours, minutes, seconds, completed } = e

    if (!completed) {
      return (
        <span>
          {hours < 10 ? `0${hours}` : hours}:
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )
    } else {
      return site.siteMetadata.shop.mintEnabled ? "Mint Now" : "Sold Out"
    }
  }

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    const video = videoRef.current

    if (visible) {
      video.play()
    }
  }, [visible])

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
    setShowDisclaimer(false)
  }
  const handleToggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer)
    setShowDetails(false)
  }

  return (
    <section className="w-full">
      {width > 0 && height > 0 ? (
        <video
          ref={videoRef}
          src={isLandscape ? SabucaseyPDPLandscape : SabucaseyPDPPortrait}
          playsInline
          autoPlay
          muted
          loop
          preload="none"
          controls={false}
          className="w-full"
        />
      ) : null}

      <h1 className="absolute top-0 left-1/2 mt-[66px] flex -translate-x-1/2 flex-col items-center justify-center text-center font-gravity-wide text-[24px] font-black uppercase leading-[1.2] md:text-[40px] lg:text-[2.75vw]">
        <div>Sabukaru X OREMOB</div>
        <div className="flex flex-row items-start gap-[0.2em]">
          <span>Drop</span>
          <DropNumber01 className="h-[32px] w-auto md:h-[48px] lg:h-[4.5vw] lg:w-[4.5vw]" />
        </div>
      </h1>

      <div className="absolute bottom-4 left-1/2 flex w-11/12 -translate-x-1/2 flex-col gap-4 text-center font-gravity-wide text-[12px] uppercase sm:w-auto sm:min-w-[600px] lg:bottom-[2vh] lg:gap-[1.5vh] lg:text-[14px]">
        <div className="grid grid-cols-1 gap-4 text-left font-sans text-[12px] font-normal normal-case md:grid-cols-2 lg:gap-6 lg:text-[14px]">
          <div className="flex w-full items-end [grid-area:1/1] md:[grid-area:auto]">
            <div
              className={`relative flex w-full flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 backdrop-blur-lg transition-opacity duration-150 ease-in-out ${
                showDetails ? "opacity-100" : "pointer-events-none opacity-0"
              }`}
            >
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDetails}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <div className="space-y-[8px]">
                <h3 className="font-gravity-wide text-[14px] font-black uppercase leading-tight">
                  1 X SABUKARU X OREMOB – SHIRT (DIGITAL + PHYSICAL)
                </h3>
                <div className="leading-tight">
                  Utility:
                  <ol className="list-disc pl-4">
                    <li>
                      Eligible Voucher for physical - SABUKARU x OREMOB SHIRT
                    </li>
                    <li>Future Metaverse exclusive character garment</li>
                    <li>Incl. Shipping WW</li>
                  </ol>
                </div>
              </div>
              <div className="space-y-[8px]">
                <h3 className="font-gravity-wide text-[14px] font-black uppercase leading-tight">
                  1 X SABUKARU X OREMOB - BULLET ARTIFACT (DIGITAL)
                </h3>
                <div className="leading-tight">
                  Utility:
                  <ol className="list-disc pl-4">
                    <li>Eligible for future WL</li>
                    <li>
                      SABUKARU x OREMOB Drops – Future Discounts on S x O
                      collabs
                    </li>
                    <li>Being Airdropped post mint (24hours).</li>
                    <li>*First pay first served</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full items-end [grid-area:1/1] md:[grid-area:auto]">
            <div
              className={`relative flex w-full flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 backdrop-blur-lg transition-opacity duration-150 ease-in-out ${
                showDisclaimer ? "opacity-100" : "pointer-events-none opacity-0"
              }`}
            >
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDisclaimer}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-none tracking-wide">
                Disclaimer:
              </h3>
              <ol className="list-disc pl-4 leading-tight">
                <li>KYC mandatory for shipping</li>
                <li>Bullet Token Discount cannot be stacked</li>
                <li>Each BPM Member only eligible for 1 Mint</li>
                <li>
                  In collaboration with world renown SABUKARU Magazine this
                  Shirt Drop is very limited
                </li>
                <li>Available for sale via BPM, OREMOB holders</li>
                <li>BPM 2 Hour window / OREMOB PFP / PUBLIC SALE</li>
                <li>Exclusive Sizes in M, L, XL</li>
                <li>First Come first Served</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 lg:gap-6">
          <button
            className={`flex w-[160px] items-center justify-center justify-self-end rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
              showDetails ? "bg-green" : "bg-white"
            }`}
            onClick={handleToggleDetails}
          >
            Details
          </button>
          <button
            className={`flex w-[160px] items-center justify-center rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
              showDisclaimer ? "bg-green" : "bg-white"
            }`}
            onClick={handleToggleDisclaimer}
          >
            Disclaimer
          </button>
        </div>
        <div className="flex flex-col leading-none">
          <span className="text-[0.9em]">Price:</span>
          <span className="text-[1.3em]">550 ADA</span>
        </div>
        <div className="grid grid-cols-2 gap-4 lg:gap-6">
          <div className="justify-self-end">
            <div>BPM Pre-Sale</div>
            <a
              className={`flex w-[160px] items-center justify-center rounded-full py-2 px-4 text-[12px] uppercase leading-none text-black transition-colors duration-200 ease-in-out hover:text-black focus:text-black lg:w-[220px]
               ${
                 !bpmPreSaleStarted
                   ? "pointer-events-none cursor-not-allowed"
                   : ""
               }
               ${
                 holdersSaleStarted
                   ? "pointer-events-none cursor-not-allowed bg-green/50"
                   : "bg-green"
               }
               ${
                 !site.siteMetadata.shop.mintEnabled
                   ? "pointer-events-none cursor-not-allowed bg-green/50"
                   : ""
               }`}
              href={`${bpmPreSaleStarted ? mintUrl : "#"}`}
            >
              <Countdown
                date={new Date(site.siteMetadata.shop.bpmPreSaleStart)}
                onMount={({ completed }) => {
                  setBpmPreSaleStarted(completed)
                }}
                onComplete={handleBpmSaleStarted}
                renderer={renderCountdown}
              />
            </a>
          </div>
          <div className="justify-self-start">
            <div>OREMOB Sale</div>
            <a
              className={`flex w-[160px] items-center justify-center rounded-full bg-green py-2 px-4 text-[12px] uppercase leading-none text-black transition-colors duration-200 ease-in-out hover:text-black focus:text-black lg:w-[220px] ${
                holdersSaleStarted
                  ? ""
                  : "pointer-events-none cursor-not-allowed"
              }
              ${
                !site.siteMetadata.shop.mintEnabled
                  ? "pointer-events-none cursor-not-allowed bg-green/50"
                  : ""
              }`}
              href={`${holdersSaleStarted ? mintUrl : "#"}`}
            >
              <Countdown
                date={new Date(site.siteMetadata.shop.holdersSaleStart)}
                onMount={({ completed }) => {
                  setHoldersSaleStarted(completed)
                }}
                onComplete={handleHoldersSaleStarted}
                renderer={renderCountdown}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PDP

import React, { useEffect, useRef, useState } from "react"
import { Transition } from "@headlessui/react"
import isTouchDevice from "is-touch-device"
import { useWindowSize } from "@react-hook/window-size"
import SabucaseyIntroLandscape from "../../videos/shop/sabucasey-intro-landscape.mp4"
import SabucaseyIntroLandscapePoster from "../../videos/shop/sabucasey-intro-landscape.jpg"
import SabucaseyIntroPortrait from "../../videos/shop/sabucasey-intro-portrait.mp4"
import SabucaseyIntroPortraitPoster from "../../videos/shop/sabucasey-intro-portrait.jpg"
import DragGachaball from "./challenges/drag-gachaball"
import PushCoin from "./challenges/push-coin"
import MoveAround from "./challenges/move-around"
import ClickThreshold from "./challenges/click-threshold"
import Tap from "./challenges/tap"
import SpinKnob from "./challenges/spin-knob"

type Props = {
  onSkipIntro: () => void
}

const Animation: React.FC<Props> = ({ onSkipIntro }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const [playing, setPlaying] = useState(false)
  const [challenge, setChallenge] = useState("")
  const [interrupted, setInterrupted] = useState(false)
  const [interrupted1, setInterrupted1] = useState(false)
  const [interrupted2, setInterrupted2] = useState(false)
  const [interrupted3, setInterrupted3] = useState(false)
  const [interrupted4, setInterrupted4] = useState(false)
  const [interrupted5, setInterrupted5] = useState(false)
  const [completedIds, setCompletedIds] = useState<Set<number>>(new Set())

  const supportsTouch = isTouchDevice()
  const [width, height] = useWindowSize()
  const [isLandscape, setIsLandscape] = useState(width > height)

  useEffect(() => {
    setIsLandscape(width > height)
  }, [width, height])

  const handleTimeUpdate = (e: React.ChangeEvent<HTMLVideoElement>) => {
    e.persist()
    const { currentTime } = e.target

    if (currentTime >= 3 && currentTime < 4 && !completedIds.has(1)) {
      setInterrupted(true)
      setInterrupted1(true)
      setChallenge(
        supportsTouch
          ? "Tap to view the coin"
          : "Move your mouse to view the coin"
      )
    } else if (currentTime >= 12 && currentTime < 13 && !completedIds.has(2)) {
      setInterrupted(true)
      setInterrupted2(true)
      setChallenge(
        supportsTouch
          ? "Swipe the coin into the coin slot"
          : "Push the coin into the coin slot"
      )
    } else if (currentTime >= 17 && currentTime < 18 && !completedIds.has(3)) {
      setInterrupted(true)
      setInterrupted3(true)
      setChallenge(supportsTouch ? "Spin the knob" : "Spin the knob")
    } else if (currentTime >= 27 && currentTime < 28 && !completedIds.has(4)) {
      setInterrupted(true)
      setInterrupted4(true)
      setChallenge(
        supportsTouch ? "Drag out the Gachaball" : "Drag out the Gachaball"
      )
    } else if (currentTime >= 35 && currentTime < 36 && !completedIds.has(5)) {
      setInterrupted(true)
      setInterrupted5(true)
      setChallenge(
        supportsTouch
          ? "Tap as fast as you can to open the Gachaball"
          : "Click as fast as you can to open the Gachaball"
      )
    }
  }

  const addCompletedId = (num: number) =>
    setCompletedIds(new Set([...completedIds, num]))

  const handlePlaying = () => setPlaying(true)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    const video = videoRef.current

    if (interrupted) {
      video.pause()
    } else {
      video.play()
      setChallenge("")
    }
  }, [interrupted])

  return (
    <>
      <section
        className="relative flex aspect-[600/1066] flex-col items-center justify-center landscape:aspect-video landscape:w-full"
        ref={containerRef}
      >
        {interrupted && interrupted1 ? (
          supportsTouch ? (
            <Tap
              onComplete={() => {
                addCompletedId(1)
                setInterrupted1(false)
                setInterrupted(false)
              }}
            />
          ) : (
            <MoveAround
              onComplete={() => {
                addCompletedId(1)
                setInterrupted1(false)
                setInterrupted(false)
              }}
            />
          )
        ) : null}
        {interrupted && interrupted2 ? (
          <PushCoin
            onComplete={() => {
              addCompletedId(2)
              setInterrupted2(false)
              setInterrupted(false)
            }}
          />
        ) : null}
        {interrupted && interrupted3 ? (
          <SpinKnob
            onComplete={() => {
              addCompletedId(3)
              setInterrupted3(false)
              setInterrupted(false)
            }}
          />
        ) : null}
        {interrupted && interrupted4 ? (
          <DragGachaball
            onComplete={() => {
              addCompletedId(4)
              setInterrupted4(false)
              setInterrupted(false)
            }}
          />
        ) : null}
        {interrupted && interrupted5 ? (
          <ClickThreshold
            onComplete={() => {
              addCompletedId(5)
              setInterrupted5(false)
              setInterrupted(false)
            }}
          />
        ) : null}

        {width > 0 && height > 0 ? (
          <video
            ref={videoRef}
            src={isLandscape ? SabucaseyIntroLandscape : SabucaseyIntroPortrait}
            poster={
              isLandscape
                ? SabucaseyIntroLandscapePoster
                : SabucaseyIntroPortraitPoster
            }
            playsInline
            autoPlay
            muted
            controls={false}
            className="w-full object-contain"
            onPlaying={handlePlaying}
            onTimeUpdate={handleTimeUpdate}
            onEnded={onSkipIntro}
          />
        ) : null}
      </section>
      <div className=" absolute bottom-[calc(1rem+43px+1rem)] left-1/2 inline-flex -translate-x-1/2 items-center text-center font-gravity-wide text-[14px] uppercase leading-none text-green md:bottom-[calc(1rem+37px+1.5rem)] lg:h-[37px] xl:bottom-[2vh]">
        {challenge}
      </div>

      <Transition
        show={playing}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button
          className="absolute bottom-4 left-1/2 z-[1338] flex -translate-x-1/2 items-center justify-center whitespace-nowrap rounded-full bg-green py-2 px-4 text-center font-gravity-wide text-[12px] uppercase leading-normal text-black md:text-[14px] lg:bottom-[2vh] lg:left-auto lg:right-4 lg:translate-x-0"
          onClick={onSkipIntro}
        >
          Skip Animation
        </button>
      </Transition>
    </>
  )
}

export default Animation

import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Animation from "../components/shop/animation"
import PDP from "../components/shop/pdp"
import { Transition } from "@headlessui/react"

const Shop: React.FC = () => {
  const [skipIntro, setSkipIntro] = useState(false)

  const handleSkipIntro = () => setSkipIntro(true)

  return (
    <Layout bgColor="black">
      <SEO title="Shop" />

      <div className="relative flex h-screen flex-col items-center justify-center">
        <Transition
          show={!skipIntro}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center">
            <Animation onSkipIntro={handleSkipIntro} />
          </div>
        </Transition>
        <div
          className={`absolute inset-0 flex h-full w-full flex-col items-center justify-center ${
            !skipIntro ? "invisible" : ""
          }`}
        >
          <PDP visible={!skipIntro} />
        </div>
      </div>
    </Layout>
  )
}

export default Shop

import React, { useEffect, useRef } from "react"

type Props = {
  onComplete: () => void
}

// Clear recorded clicks after X ms of inactivity
const TIMEOUT_MS = 350
// Require X clicks without running into the timeout
const CLICKS_REQUIRED = 8

const ClickThreshold: React.FC<Props> = ({ onComplete }) => {
  const actionAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!actionAreaRef.current) {
      return
    }

    let timer: NodeJS.Timeout
    let numClicks = 0

    function onClick(e: PointerEvent) {
      e.preventDefault()

      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        numClicks = 0
      }, TIMEOUT_MS)

      numClicks++

      if (numClicks >= CLICKS_REQUIRED) {
        onComplete()
      }
    }

    actionAreaRef.current.addEventListener("pointerdown", onClick)

    return () => {
      actionAreaRef.current?.removeEventListener("pointerdown", onClick)
    }
  }, [actionAreaRef])

  return (
    <div
      ref={actionAreaRef}
      className="absolute inset-0 z-[1337] h-full w-full cursor-pointer"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="absolute top-1/2 left-1/2 h-[32px] w-[32px] -translate-x-1/2 -translate-y-1/2 landscape:h-[56px] landscape:w-[56px]">
          <div className="absolute left-[8px] top-[8px] inline-flex h-[32px] w-[32px] animate-pulsate rounded-[50%] border-[3px] border-green opacity-0 [animation-duration:0.2s] landscape:h-[56px] landscape:w-[56px]"></div>
          <div className="absolute top-[16px] left-[16px] h-[16px] w-[16px] rounded-[50%] bg-green landscape:left-[20px] landscape:top-[20px] landscape:h-[32px] landscape:w-[32px]"></div>
        </div>
      </div>
    </div>
  )
}

export default ClickThreshold

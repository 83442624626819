import React, { useEffect, useRef } from "react"

type Props = {
  onComplete: () => void
}

const MoveAround: React.FC<Props> = ({ onComplete }) => {
  const actionAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!actionAreaRef.current) {
      return
    }

    const requiredDistance = Math.ceil(
      (document.documentElement.clientWidth +
        document.documentElement.clientHeight) /
        3
    )
    const lastPos: { x: number | null; y: number | null } = {
      x: null,
      y: null,
    }
    let travelDistance = 0

    const onPointerMove = (e: PointerEvent) => {
      if (lastPos.x && lastPos.y) {
        travelDistance += Math.sqrt(
          Math.pow(lastPos.y - e.clientY, 2) +
            Math.pow(lastPos.x - e.clientX, 2)
        )

        if (travelDistance > requiredDistance) {
          onComplete()
        }
      }

      lastPos.x = e.clientX
      lastPos.y = e.clientY
    }

    actionAreaRef.current.addEventListener("pointermove", onPointerMove)

    return () => {
      actionAreaRef.current?.removeEventListener("pointermove", onPointerMove)
    }
  }, [actionAreaRef])

  return (
    <div
      ref={actionAreaRef}
      className="absolute inset-0 z-[1337] h-full w-full"
    ></div>
  )
}

export default MoveAround

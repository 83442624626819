import { useState, useEffect, MutableRefObject } from "react"

const useCheckpoints = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [completed, setCompleted] = useState(false)

  let target: number | null = null
  let current = 0

  function onPointerDown(e: PointerEvent) {
    e.preventDefault()

    const actionArea = ref.current
    if (!actionArea) {
      return
    }

    current = 0
    actionArea.addEventListener("pointerup", onPointerUp)
    actionArea.addEventListener("pointermove", onPointerMove)
  }

  function onPointerMove(e: PointerEvent) {
    e.preventDefault()

    const actionArea = ref.current
    if (!actionArea) {
      return
    }

    const target = document.elementFromPoint(
      e.clientX,
      e.clientY
    ) as HTMLElement | null

    if (target) {
      checkOverElement(target)
    }
  }

  function checkOverElement(target: HTMLElement) {
    const orderAttr = target.getAttribute("data-order")

    if (!orderAttr) {
      return
    }

    const order = parseInt(orderAttr, 10)

    if (isNaN(order)) {
      return
    }

    if (current + 1 === order) {
      current += 1
    }
  }

  function onPointerUp(e: PointerEvent) {
    e.preventDefault()

    const actionArea = ref.current
    if (!actionArea) {
      return
    }

    actionArea.removeEventListener("pointermove", onPointerMove)
    actionArea.removeEventListener("pointerup", onPointerUp)

    if (target === current) {
      setCompleted(true)
    }
  }

  useEffect(() => {
    const actionArea = ref.current

    if (!actionArea) {
      return
    }

    target = actionArea.querySelectorAll("[data-order]").length

    actionArea.addEventListener("pointerdown", onPointerDown)

    return () => {
      actionArea.removeEventListener("pointerup", onPointerUp)
      actionArea.removeEventListener("pointerdown", onPointerDown)
      actionArea.removeEventListener("pointermove", onPointerMove)
    }
  }, [])

  return completed
}

export default useCheckpoints
